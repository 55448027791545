import { createContext, useContext, useEffect, useState } from "react";
import RESTCall from "./RESTCall";
import { redirect, getPart } from "./UrlFunctions";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthContextProvider = ({children}) => {
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        RESTCall('session', {}, 'GET', function(data){ userObjectResponse('session_check', data); });
    }, []);

    useEffect(() => {
        console.log(session,"kkk");
        if (session) {
            getUser();
        } else {
            setUser(null);
        }
    }, [session]);

    async function getUser() {        
        setUser(session);
    }

    async function getChats(id) {
        const chats = await RESTCall('get_chats/'+id, {}, 'GET');
        return chats;
    }

    async function getUsers() {
        const users = await RESTCall('users', {}, 'GET');
        return users;
    }

    async function contactLawyer(propertyId, message) {
        const response = await RESTCall('contact_lawyer/'+propertyId, {message: message}, 'POST');
        return response;
    }

    async function deleteChat(id) {
        const response = await RESTCall('chat/'+id, {}, 'DELETE');
        return response;
    }

    async function getSampleQuestions() {
        const questions = await RESTCall('example_questions', {}, 'GET');
        return questions;
    }

    async function getCaseSummary(id) {
        const summary = await RESTCall('summarize_case/'+id, {}, 'GET');
        return summary;
    }

    async function uploadFormFile(file,callback) {
        const response = await RESTCall("form_upload", { file }, 'FILE');
        callback(response);
        return response;
    }

    async function sendFormQuestion(question, form_id, currentChat) {
        const response = await RESTCall('form-question', {session_id: currentChat, form_id: form_id, question: question}, 'POST');
        return response;
    }
    async function sendQuestion(question, currentChat) {
        const response = await RESTCall('question', {question: question, session_id: currentChat}, 'POST');
        return response;
    }
    
    async function sendFormAnswer(question, form_id, session_id){
        const response = await RESTCall('answer_form_questions', {question: question, form_id: form_id, session_id: session_id}, "POST");
        if(response == null){
            return {"success": false,"error": "No reply","message": "Done."};
        }
        return response;
    }

    async function updateFormField(form_id, session_id, field_name, field_type, extras){
        const body = {
            form_id: form_id,
            session_id: session_id,
            field_type: field_type,
            field_name: field_name,
            extras: JSON.stringify(extras)
        };
        const response = await RESTCall('update_form_field', body, 'POST');
        return response;
    }

    async function verifyPaymentIntent(clientSecret, paymentIntentId) {
        const response = await RESTCall('verify-stripe-payment', {intent_id: paymentIntentId, client_secret: clientSecret}, 'POST');
        if(response?.success) {
           
            setSession((prev) => ({...prev, user: {...prev.user, credits: response.result.credits}}));
        }
        return response;
    }

    async function getTransacitons() {
        const response = await RESTCall('transactions', {}, 'GET');
        
        return response;
    }

    async function flagPost(messageId) {
        const response = await RESTCall('flag_post/'+messageId, {}, 'PATCH');
        return response;
    }

    async function adminApprovePost(messageId) {
        const response = await RESTCall('admin_approve_post/'+messageId, {}, 'PATCH');
        return response;
    }

    async function adminFlagPost(messageId) {
        const response = await RESTCall('admin_flag_post/'+messageId, {}, 'PATCH');
        return response;
    }

    async function adminFeaturePost(messageId, status) {
        const response = await RESTCall('admin_feature_post/'+messageId+'/'+status, {}, 'PATCH');
        return response;
    }

    async function getUploadFormInfo(id) {
        const response = await RESTCall('upload_form_info/'+id, {}, 'GET');
        return response;
    }

    async function getLegislations() {
        const response = await RESTCall('legislations', {}, 'GET');
        return response;
    }

    async function getFormsList() {
        const forms = await RESTCall('forms', {}, 'GET');
        return forms;
    }

    async function getFormInfo(id) {
        const form = await RESTCall('form_info/'+id, {}, 'GET');
        return form;
    }

    async function getForm(id) {
        if(id === 'new') return {success: false};
        const form = await RESTCall('form/'+id, {}, 'GET');
        return form;
    }

    async function approvePost(messageId) {
        const response = await RESTCall('approve_post/'+messageId, {}, 'PATCH');
        return response;
    }

    async function getUserChats(id) {
        const chats = await RESTCall('user_chats/'+id, {}, 'GET');
        return chats;
    }

    async function fetchChat(chatId) {
        const chat = await RESTCall('get_chat/'+chatId, {}, 'GET');
        return chat;
    }

    async function adminEditPost(messageId, message, legislation_id, section, subsection, user_message) {
        var response;
        if(user_message === null) response = await RESTCall('admin_edit_post/'+messageId, {message: message, legislation: legislation_id, section: section, subsection: subsection}, 'POST');
        else response = await RESTCall('admin_edit_post/'+messageId, {message: message, legislation: legislation_id, section: section, subsection: subsection, user_message: user_message}, 'POST');
        return response;
    }

    async function fetchForm(sessionId) {
        const chat = await RESTCall('form/'+sessionId, {}, 'GET');
        return chat;
    }

    async function fetchFormQuestions(){
        const forms = await RESTCall("form_questions/", {}, "GET");
        return forms;
    }

    async function signUp(email, password, first_name, last_name) {
        const signUpData = await RESTCall('create_user', {email: email, password: password, first_name: first_name, last_name: last_name}, 'POST');
        return signUpData;
    }

    async function resetPassword(email) {
        const data = await RESTCall('reset_password', {email: email}, 'POST');
        return data;
    }



    async function changePassword(password) {
        // const { data, error } = await supabase.auth.updateUser({
        //     password
        // });
        // if (error) {
        //     console.error(error);
        // }
    }

    async function signInWithEmail(loginInfo, password) {
        let result_valid = false;
        
        await RESTCall('login', {email: loginInfo, password: password}, 'POST', function(data){ userObjectResponse('login', data); }).then(result => { 
            result_valid = result; 
        });

        return result_valid;
    };

    async function userObjectResponse(type, response) {
        
        if(response?.success) {
            if(response.user === null || typeof response.user.id === 'undefined') {
                setSession(false);
                localStorage.removeItem("token");
                redirect('/login/');
                return;
            }            

            const userData = { user: 
                {
                    id: response.user.id,
                    email: response.user.email,
                    auth_user_id: response.user.auth_user_id,
                    admin: response.user.admin,
                    moderator: response.user.moderator,
                    first_name: response.user.first_name,
                    last_name: response.user.last_name,
                    credits: response.user.credits                    
                }
            }
            localStorage.setItem("token", response.user.id);
            if(getPart(1) === 'login') redirect('/app/');

            setLoginFailed(false);
            setIsLoggedIn(true);
            setSession(userData);
        } else {
            if(type === 'login') {
                setLoginFailed(true);
                return false;
            }
            setIsLoggedIn(false);
            localStorage.removeItem("token", response.user.id);
            redirect('/login/');
        }

        return true;
    }

    async function getPaymentIntent() {
        const paymentIntent = await RESTCall('create-payment-intent', {user: session.user.id, amount: 2000}, 'POST');
        return paymentIntent;
    }

    async function signOut() {
        await RESTCall('logout', {}, 'GET', function(data){ userObjectResponse('logout', data); });
        setIsLoggedIn(false);
        setSession(false);
    };

    async function getFormFields(sessionId, callback) {
        const fields = await RESTCall('form_fields/'+sessionId, {}, 'GET');
        callback(fields);
    }

    async function updateForm(form_id, title, code, legislation, description) {
        const response = await RESTCall('update_form/'+form_id, {title: title, code: code, legislation: legislation, description: description}, 'POST');
        return response;
    }

    return (
        <AuthContext.Provider value={{
            isLoggedIn, session, signInWithEmail, loading, getChats, fetchChat, setLoading, signOut, signUp, resetPassword, changePassword, contactLawyer,
            user, loginFailed, sendQuestion, flagPost, approvePost, getUsers, adminApprovePost, adminFeaturePost, adminFlagPost, getUserChats, getSampleQuestions,
            sendFormAnswer, getFormsList, getForm, sendFormQuestion, getTransacitons, fetchForm, getFormInfo, getPaymentIntent, getLegislations, deleteChat,
            verifyPaymentIntent, getCaseSummary, uploadFormFile, fetchFormQuestions, adminEditPost, updateFormField, getFormFields, getUploadFormInfo, updateForm }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;