export const getPart = (idx) => {
    const parts = window.location.pathname.split("/");
    if (parts.length < idx+1)
        return false;
    return parts[idx];
}

export const redirect = (url) => {
    const currentUrlMain = getPart(1);
    const currentUrlSub = getPart(2);
    const currentUrlSub2 = getPart(3);

    const urlParts = url.split("/");
    const urlMain = urlParts[1];

    if(urlMain === currentUrlMain) {
        if(urlParts.length > 2) {
            const urlSub = urlParts[2];
            if(urlSub === currentUrlSub) {
                if(urlParts.length > 3) {
                    const urlSub2 = urlParts[3];
                    if(urlSub2 === currentUrlSub2) {
                        window.location.reload();
                    } else {
                        window.location.href = url;
                    }
                } 
            } else {
                window.location.href = url;
            }
        } 
    } else {
        window.location.href = url;
    }
}
    