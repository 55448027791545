
const restAPI = process.env.REACT_APP_REST_API_URL;

const RESTCall = async (api_function, params, method, callback) => {

    let url = restAPI + '/' + api_function;
    let response_data = null;
    let is_file = false;
    let type = "application/json";

    if(method === 'FILE'){
        is_file = true;
        method = 'POST';
        type = "multipart/form-data";
    }

    let options = {
        method,
        credentials: 'include',
        headers: {
            "Access-Control-Allow-Origin":`${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
        }
    };
    if ( 'GET' === method ) {
        url += '?' + ( new URLSearchParams( params ) ).toString();
        options.headers['Content-Type'] = type;
            
    } else {
        if(is_file){
            var data = new FormData()
            for ( var key in params ) {
                data.append( key, params[key] )
            }
            options.body = data;
        } else {
            options.body = JSON.stringify( params );
            options.headers['Content-Type'] = type;
        }
    }

    await fetch( url, options )
        .then(res => res.json())
        .then(
            ( response ) => {
            if(typeof callback === 'function')
                callback(response);
            response_data = response;
            },
            (error) => {
                console.log(error);
            }
        );
        
    return response_data;
}


export default RESTCall;