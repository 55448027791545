import React, { createContext, useState, useContext, useReducer, useEffect } from "react";
import AuthContext from "./AuthContext";
import { getPart } from "./UrlFunctions";
import { useLocation } from 'react-router-dom';


const DATA_CONTEXT = {
    chats: [],
    current_chat: []
}

const dataReducer = (state, action) => {
    switch (action.type) {
        case "SET_CHATS":
            return { ...state, chats: action.payload };
        case 'ADD_CHAT':
            const exists = state.chats.filter(chat => chat.id === action.payload.id);
            if (exists.length > 0) return state;
            return { ...state, chats: [action.payload, ...state.chats] };
        case 'INCREASE_CHAT_CREDITS':
            return { ...state, chats: state.chats.map(chat => chat.id === action.payload.id ? { ...chat, total_credits: chat.total_credits + action.payload.tokens } : chat) };
        case "UPDATE_CHAT_FIELD":
            return { ...state, chats: state.chats.map(chat => chat.id === action.payload.id ? { ...chat, [action.payload.field]: action.payload.value } : chat) };
        case 'UPDATE_CURRENT_CHAT_FIELD':
            return { ...state, current_chat: state.current_chat.map(message => message.id === action.payload.id ? { ...message, [action.payload.field]: action.payload.value } : message) };
        case "SET_CURRENT_CHAT":
            return { ...state, current_chat: action.payload };
        case "DELETE_CHAT":
            return { ...state, chats: state.chats.filter(chat => chat.id !== action.payload) };
        case "ADD_CHAT_MESSAGE":
            return { ...state, current_chat: [...state.current_chat, action.payload] };
        default:
            return state;
    }
}

const DataContext = createContext(DATA_CONTEXT);

export const DataContextProvider = (props) => {
    const authContext = useContext(AuthContext);
    const [data, dispatch] = useReducer(dataReducer, DATA_CONTEXT);
    const [currentChat, setCurrentChat] = useState(null);
    const [pageContext, setPageContext] = useState(null);
    const [contextId, setContextId] = useState(null);
    const [propertyId, setPropertyId] = useState(null);
    const location = useLocation();
    const [sidebar, setSidebar] = useState(false);
    const [sidebarRef, setSidebarRef] = useState(null);
    const [ pageRef, setPageRef ] = useState(null);

    const processContext = () => {
        switch(getPart(2)){
            case 'chat':
                setPageContext('chat');
                break;
            case '':
                setPageContext('home');
                break;
            case 'form':
            case 'forms':
                setPageContext('form');
                break;
            case 'upload_form':
            case 'form_question':
                setPageContext("form_question");
                break;
            case 'billing':
                setPageContext('billing');
                break;
            case 'user':
                setPageContext('user');
                break;
            case 'admin':
                setPageContext('admin');
                break;
        }

        
        if(getPart(3)){
            setContextId(getPart(3));
            setPropertyId(getPart(3));
            if(getPart(4)){
                setPropertyId(getPart(4));
            }
        } else {
            setContextId(null);
            setPropertyId(null);
        }
    }

    useEffect(() => {
        processContext();
    }, [location]);
    
    const getUserChats = (id, callback) => {
        if(typeof id === 'undefined') return;
        authContext.getUserChats(id).then(result => {
            if (result?.success) {
                callback(result.chats);
            }
        });
    }

    const getChats = (id) => {
        if(typeof id === 'undefined') {
            id = 'mine';
        }
        authContext.getChats(id).then(result => {;
            if (result?.success) {
                dispatch({ type: "SET_CHATS", payload: result.chats });
            }
        });
    }

    const fetchChat = (chatId, callback) => {
        authContext.fetchChat(chatId).then(result => {
            if (result?.success) {
                dispatch({ type: "SET_CURRENT_CHAT", payload: result.chat });

                let found = data.chats.filter(chat => chat.id === chatId);
                if (found.length === 0) {
                    dispatch({ type: "ADD_CHAT", payload: result.info });
                } else {
                    dispatch({ type: "UPDATE_CHAT_FIELD", payload: { id: chatId, field: 'total_credits', value: result.info.total_credits } });
                    dispatch({ type: "UPDATE_CHAT_FIELD", payload: { id: chatId, field: 'title', value: result.info.title } });
                }
            }
            if (callback) { callback(result.chat); }
        });
    }
    const fetchFormQuestions = (callback) => {
        authContext.fetchFormQuestions().then(result => {
            if(result != null && result?.success){
                var data = result.data;
                for(let i = 0; i < data.length; i++){
                    var cur = data[i];
                    cur.fields = cur.fields;
                    cur.questions = cur.questions; // Somehow a string is coming out as ``
                    cur.path=cur.title;
                    cur.name=cur.title;
                    cur.description=cur.title;
                    data[i] = cur;
                }
                callback(data);
            }
        });
    }

    const fetchForm = (sessionId, callback) => {
        authContext.fetchForm(sessionId).then(result => {
            if (result?.success) {
                let found = 0;
                if(data.chats.length > 0) {
                    found = data.chats.filter(chat => chat.id === sessionId);
                    found = found.length;
                }
                
                if (found === 0) {
                    dispatch({ type: "ADD_CHAT", payload: result.info });
                } else {
                    dispatch({ type: "UPDATE_CHAT_FIELD", payload: { id: sessionId, field: 'title', value: result.info.form.title } });
                }
                dispatch({ type: "SET_CURRENT_CHAT", payload: result.chat });
            }
            if (callback) { callback(result); }
        });
    }

    return (
        <DataContext.Provider value={{ data, dispatch, getChats, sidebarRef, setSidebarRef,  pageRef, setPageRef, currentChat, sidebar, setSidebar, setCurrentChat, fetchChat, fetchForm, fetchFormQuestions, 
        pageContext, contextId, propertyId, getUserChats}}>
            {props.children}
        </DataContext.Provider>
    )
}


export default DataContext;