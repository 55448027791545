import React, { lazy, Suspense, useEffect, useContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import checkAuth from './app/auth';
import initializeApp from './app/init';
import { AuthContextProvider } from "./utils/AuthContext";
import { DataContextProvider } from "./utils/DataContext";


const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const Documentation = lazy(() => import('./pages/Documentation'));

initializeApp();

const token = checkAuth();

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (

    <Router>
        <AuthContextProvider>
            <DataContextProvider>
                <Suspense fallback={<div>Loading...</div>}> {/* Suspense / Fallback */}
                    <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/documentation" element={<Documentation />} />

                    {/* New routes above here */}
                    <Route path="/app/*" element={<Layout />} />

                    <Route path="*" element={<Navigate to={token ? "/app/" : "/login"} replace />} />
                    {/* <Route path="*" element={<Navigate to={"/login"} replace />} /> */}
                    </Routes>
                </Suspense>
            </DataContextProvider>
        </AuthContextProvider>
    </Router>
  );
}

export default App;
